<template lang="pug">
  div
    TheHeader
    v-layout.auth_activation(align-center, justify-center, column, fill-height)
      section
        .auth_activation__title {{ $t('messages.error.accountAlreadyActivatedTitle') }}
        p {{ $t('messages.error.accountAlreadyActivatedDesc') }}
        v-layout(column)
          router-link.main_link(v-if="!user.loggedIn", :to="{ name: 'signin' }") {{ $t('actions.signIn') }}
            SvgArrowRight.main_link_img
          .main_link(v-else, @click="goToService()") {{ $t('actions.signIn') }}
            SvgArrowRight.main_link_img
</template>

<script>
import SvgCheck from '@/components/svg/SvgCheck';
import SvgArrowRight from '@/components/svg/SvgArrowRight';
import TheHeader from '@/components/TheHeader';

export default {
  components: { SvgCheck, SvgArrowRight, TheHeader },
  computed: {
    user() { return this.$store.state.user; }
  },
  mounted() {
    if (!this.user.loggedIn) {
      localStorage.setItem('referrer', process.env.VUE_APP_KICKEX);
    }
  },
  methods: {
    goToService() {
      if (!this.user.registration_country_id) return;

      const serviceId = 3;
      const redirectUrl = process.env.VUE_APP_KICKEX;

      this.$store.dispatch('user/signinService', { serviceId, redirectUrl, router: this.$router });
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .auth_activation {
    max-width: 550px;
    min-height: 100vh;
    padding: 0 10px;
    margin: auto;
    text-align: left;

    section {
      position: relative;
      padding-left: 80px;
      width: 100%;
    }

    &__title,
    p {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 52px;
      line-height: 1;
      color: $light-theme-primary-text;
      text-align: left;

      .svg_check {
        position: absolute;
        left: 0px;

        ::v-deep path { fill: $green !important; }
      }
      @media screen and (max-width: 400px) { font-size: 28px; }
    }

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
    }

    .main_link {
      margin-top: 15px;
      &:first-of-type { margin-top: auto; }
    }
  }
</style>
